import React, {Component} from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/barn.jpg';
import pic2 from '../assets/images/food.jpg';
import pic3 from '../assets/images/taxi.jpg';
import pic4 from '../assets/images/confetti.jpg';

import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h7>{config.heading}</h7>
        <p>{config.subHeading}</p>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Information & RSVP
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            We're getting married!
          </h2>
          <p>
            We cannot wait to share our wedding day with you! 
            <br /> <br />
            This website is full of information about the day, along with contact details of taxi companies, <br />
            local hotels, and the venue.

            <br /><br />
            <Event>Add to Calendar</Event>
          </p>
        </header>
        <ul className="icons major">
          <li>
            <a href="#two">Information</a> <br /><br />
            <span className="icon fa-gem major style1">
              <span className="label">Information</span>
            </span>
          </li>
          <li>
            <a href="#three">RSVP</a> <br /><br />
            <span className="icon fa-calendar-check major style1">
              <span className='label'>RSVP</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            The Venue
          </h2>
          <p>
            Somersbury Barn <br />
            Horsham Lane, Ewhurst, Surrey, GU6 7SW <br />
            <a href='https://maps.app.goo.gl/EhkNvfvZD4vQBYidA'>Google Maps - Venue Location</a> <br />
            <br />
            Plenty of parking is available, and taxis should be pre-booked!
          </p>
            <h3>Arrival Times</h3>
            <li>Guest Arrival & Welcome Drinks - 12:15</li>
            <li>Ceremony - 13:00</li>
            <li>Evening Guests - 18:30</li>
            <li>Carriages - 23:00 - 00:00</li>
        </div>
      </section>

      <section className="spotlight style3">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            The Food
          </h2>
          <p>
            The food at the wedding breakfast will be a two-course meal, served at the tables. <br />
            Canapés will be served during the post-ceremony drinks, so you won't go hungry! <br />
            The food in the evening will be a crêpe van, serving unlimited savoury and sweet crêpes for three hours. <br />
            <br />
            If you have any intolerances or allergies, please let us know when you RSVP so we can pass it onto the caterers!
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Local Accommodation
          </h2>
          <p>
            <li><a href="https://www.thebullsheadinn.co.uk/stay">The Bulls Head Inn - Ewhurst (1.7mi)</a></li>
            <li><a href="https://www.therichardonslow.co.uk/pub-rooms-stay-cranleigh-guildford/">The Richard Onslow - Cranleigh (3.1mi)</a></li>
            <li><a href="https://www.innonthegreenockley.co.uk/en-GB/rooms">Inn on the Green - Ockley (3.9mi)</a></li>
            <li><a href="https://hurtwoodhotel.co.uk/">Hurtwood Hotel - Peaslake (4.5mi)</a></li>
          </p>
          <h2>
            Taxis (Please pre-book!)
          </h2>
          <p>
            <li><a href="https://www.cranleightaxis.co.uk/">Cranleigh Taxis - 01483 277223</a></li>
            <li><a href="https://www.rudgwickcars.co.uk/">Rudgwick Cars - 01403 822333</a></li>
            <li><a href="https://www.dorkingtaxis.co.uk/">Dorking Taxis - 01306 885533</a></li>
            <li><a href="https://www.aadorkingtaxis.co.uk/">AA Dorking Taxis - 01306 735849</a></li>
          </p>
        </div>
      </section>

      <section className="spotlight style3">
        <div className="image">
          <img src={pic4} alt="" />
        </div>
        <div className="content">
          <h2>
            Confetti
          </h2>
          <p>
            We will be supplying confetti on the day, as our venue only allow petal or dried petal confetti.
          </p>
          <h2>
            Gifts
          </h2>
          <p>
            For us, being able share our special day with us is enough of a gift. <br />
            If you really want to give us something, any small contribution to our honeymoon would be greatly appreciated! <br /> <br />
            <b>Bank Details</b> <br />
            Name: Luke Calderon <br />
            Sort Code: 60-83-71 <br />
            Account No: 2885 3284 <br />
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>RSVP</h2>
        </header>
        <iframe src='https://withjoy.com/bethluke/rsvp' title='RSVP'/>
      </div>
    </section>

  </Layout>
);

export default IndexPage;


class Event extends Component {
  constructor(props) {
      super(props);
      this.state = {
          event: {
              title: "Beth & Luke's Wedding",
              description: '',
              location: 'Somersbury Barn, Horsham Lane, Ewhurst, Surrey, GU6 7SW',
              startDate: new Date(2024, 6, 27, 12, 15),
              endDate: new Date(2024, 6, 27, 23, 0),
          },
      };
      this.saveCalInvite = this.saveCalInvite.bind(this);
  }

  render() {
      return (
          <section>
              <a onClick={this.saveCalInvite}>Add to Calendar</a>
          </section>
      )
  }

  saveCalInvite() {
      // Create the .ics URL
      let url = [
          "BEGIN:VCALENDAR",
          "VERSION:2.0",
          "BEGIN:VEVENT",
          "DTSTART:" + this.formatDate(this.state.event.startDate),
          "DTEND:" + this.formatDate(this.state.event.endDate),
          "SUMMARY:" + this.state.event.title,
          "DESCRIPTION:" + this.state.event.description,
          "LOCATION:" + this.state.event.location,
          "BEGIN:VALARM",
          "TRIGGER:-PT15M",
          "REPEAT:1",
          "DURATION:PT15M",
          "ACTION:DISPLAY",
          "DESCRIPTION:Reminder",
          "END:VALARM",
          "END:VEVENT",
          "END:VCALENDAR"
      ].join("\n");

      let blob = new Blob([url], {type: 'text/calendar;charset=utf-8'});

      if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
          // Open/Save link in IE and Edge
          window.navigator.msSaveBlob(blob, 'download.ics');
      } else {
          // Open/Save link in Modern Browsers
          window.open(encodeURI("data:text/calendar;charset=utf8," + url));
      }

  }

  formatDate(dateString) {
      let dateTime = new Date(dateString);
      return [
          dateTime.getUTCFullYear(),
          this.pad(dateTime.getUTCMonth() + 1),
          this.pad(dateTime.getUTCDate()),
          "T",
          this.pad(dateTime.getUTCHours()),
          this.pad(dateTime.getUTCMinutes()) + "00Z"
      ].join("");
  }

  pad(num) {
      // Ensure date values are double digits
      return num < 10 ? "0" + num : num;
  }

}
